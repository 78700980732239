.horizontalMenu html, .horizontalMenu body, .horizontalMenu iframe, .horizontalMenu h1, .horizontalMenu h2, .horizontalMenu h3, .horizontalMenu h4, .horizontalMenu h5, .horizontalMenu h6 {
  font: inherit;
  vertical-align: baseline;
  -webkit-font-smoothing: subpixel-antialiased;
  font-smoothing: antialiased;
  font-smooth: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: subpixel-antialiased;
  font-smoothing: subpixel-antialiased;
  font-smooth: subpixel-antialiased;
  border: 0;
  margin: 0;
  padding: 0;
  font-family: Helvetica, sans-serif;
  font-size: 12px;
  font-weight: normal;
  line-height: 18px;
}

.horizontalMenu .cl {
  clear: both;
}

.horizontalMenu img, object, embed, video {
  max-width: 100%;
  border: 0;
}

.horizontalMenu a:focus {
  outline: none;
}

.horizontalMenu:before, .horizontalMenu:after {
  content: "";
  display: table;
}

.horizontalMenu:after {
  clear: both;
}

.horizontalMenucontainer {
  background-position: 50% 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  overflow: hidden;
}

.horizontal-main {
  width: 100%;
  background: linear-gradient(#815eae, #715c94);
  position: relative;
  box-shadow: 0 8px 16px #3a3a3e33 !important;
}

.horizontal-mainwrapper {
  width: 100%;
  margin: 0 auto;
}

.desktoplogo {
  float: left;
  margin: 0;
  padding: 1.5rem 0;
}

.desktoplogo img {
  width: 100%;
}

.horizontalMenu {
  color: #fff;
  padding: 0;
  font-family: Helvetica, sans-serif;
  font-size: 14px;
  display: block;
}

.horizontalMenu > .horizontalMenu-list {
  text-align: left;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  display: block;
}

.mega-menubg {
  background: #fff;
  border: 1px solid #0000001a;
  padding: 14px 5px;
  font-size: 15px;
  box-shadow: 0 1px 15px 1px #d0c9f380 !important;
}

.horizontalMenu > .horizontalMenu-list > li {
  text-align: center;
  float: left;
  margin: 0;
  padding: 0;
  display: block;
}

.horizontalMenu > .horizontalMenu-list > li > a {
  color: #fff;
  margin: 0 2px;
  padding: 1rem .8rem;
  text-decoration: none;
  display: block;
  position: relative;
}

.horizontalMenu > .horizontalMenu-list > li:last-child > a {
  border-right: 0;
}

.horizontalMenu > .horizontalMenu-list > li a.menuhomeicon {
  padding-left: 25px;
  padding-right: 25px;
}

.horizontalMenu > .horizontalMenu-list > li > a .fa {
  font-size: 14px;
  line-height: inherit;
  vertical-align: top;
  margin-right: 12px;
  display: inline-block;
}

.horizontalMenu > .horizontalMenu-list > li > a .fa.fa-home {
  font-size: 15px;
}

.horizontalMenu > .horizontalMenu-list > li > a .arrow:after {
  content: "";
  float: right;
  height: 0;
  text-align: right;
  width: 0;
  border-top: 4px solid;
  border-left: 4px solid #0000;
  border-right: 4px solid #0000;
  margin: 0 0 0 14px;
  position: absolute;
  top: 33px;
  right: 11px;
}

.horizontalMenu > .horizontalMenu-list > li.rightmenu {
  float: right;
}

.horizontalMenu > .horizontalMenu-list > li.rightmenu a {
  border-right: none;
  padding: 0 30px 0 20px;
}

.horizontalMenu > .horizontalMenu-list > li.rightmenu a .fa {
  font-size: 15px;
}

.horizontalMenu > .horizontalMenu-list > li.rightmenu {
  float: right;
}

.horizontalMenu > .horizontalMenu-list > li.rightmenu:before, .horizontalMenu-list > li.rightmenu:after {
  content: "";
  display: table;
}

.horizontalMenu > .horizontalMenu-list > li.rightmenu:after {
  clear: both;
}

.horizontalMenu > .horizontalMenu-list > li.rightmenu > .topmenusearch {
  float: right;
  width: 210px;
  height: 39px;
  margin: 16px 0 0;
  position: relative;
}

.horizontalMenu > .horizontalMenu-list > li.rightmenu > .topmenusearch .searchicon {
  -o-transition: all .7s ease 0s;
  transition: all .7s;
}

.horizontalMenu > .horizontalMenu-list > li.rightmenu > .topmenusearch input {
  width: 100%;
  float: right;
  width: 100%;
  text-indent: 15px;
  height: 39px;
  z-index: 2;
  color: #333;
  -o-transition: all .7s ease 0s;
  background-color: #efefef;
  border: 0;
  outline: none;
  margin: 0;
  padding: 0;
  font-size: 12px;
  transition: all .7s;
  position: relative;
  top: 0;
  bottom: 0;
  right: 0;
}

.horizontalMenu > .horizontalMenu-list > li.rightmenu > .topmenusearch input::placeholder {
  color: #6b6f80;
}

.horizontalMenu > .horizontalMenu-list > li.rightmenu > .topmenusearch input:focus {
  color: #333;
  width: 230px;
}

.horizontalMenu > .horizontalMenu-list > li.rightmenu > .topmenusearch input:focus ~ .btnstyle .fa {
  color: #6b6f80;
  opacity: 1;
}

.horizontalMenu > .horizontalMenu-list > li.rightmenu > .topmenusearch input:focus ~ .searchicon {
  opacity: 1;
  z-index: 3;
  color: #fff;
}

.horizontalMenu > .horizontalMenu-list > li.rightmenu > .topmenusearch .btnstyle {
  width: 40px;
  z-index: 1;
  cursor: pointer;
  opacity: .3;
  color: #333;
  z-index: 1000;
  -o-transition: all .7s ease 0s;
  background-color: #0000;
  border: 0 solid;
  line-height: 30px;
  transition: all .7s;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.horizontalMenu > .horizontalMenu-list > li.rightmenu > .topmenusearch .btnstyle .fa {
  text-align: center;
  margin: 0;
  padding: 0;
  line-height: 37px;
}

.horizontalMenu > .horizontalMenu-list > li.rightmenu > .topmenusearch .btnstyle:hover .fa {
  opacity: 1;
}

.horizontalMenu > .horizontalMenu-list > li.rightmenu {
  zoom: 1;
}

.horizontalMenu > .horizontalMenu-list > li > ul.sub-menu {
  z-index: 1000;
  min-width: 190px;
  background-color: #fff;
  border: 1px solid #a7b4c94d;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 53px;
  box-shadow: 0 1px 15px 1px #d0c9f380;
}

.horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li {
  margin: 0;
  padding: 0;
  display: block;
  position: relative;
}

.horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > a {
  color: #646367;
  text-align: left;
  text-transform: none;
  letter-spacing: normal;
  background-image: none;
  border-right: 0 solid;
  padding: 8px 12px;
  font-size: 13px;
  line-height: 22px;
  display: block;
}

.horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > a:hover {
  text-decoration: none;
}

.horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > a > .fa {
  margin-right: 9px;
}

.horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > ul.sub-menu {
  min-width: 220px;
  background-color: #fff;
  border: 1px solid #a7b4c94d;
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
  top: 0;
  left: 100%;
  box-shadow: 0 1px 15px 1px #d0c9f380;
}

.horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > ul.sub-menu > li {
  margin: 0;
  padding: 0;
  display: block;
  position: relative;
}

.horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > ul.sub-menu > li > a {
  color: #6b6f80;
  text-align: left;
  text-transform: none;
  letter-spacing: normal;
  background-image: none;
  border-right: 0 solid;
  padding: 8px 12px;
  font-size: 13px;
  line-height: 22px;
  display: block;
}

.horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > ul.sub-menu > li > a:hover {
  text-decoration: none;
}

.horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > ul.sub-menu > li > a > .fa {
  margin-right: 9px;
}

.horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > ul.sub-menu > li > ul.sub-menu {
  min-width: 220px;
  background-color: #fff;
  border: 1px solid #a7b4c94d;
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
  top: 0;
  left: 100%;
}

.horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > ul.sub-menu > li > ul.sub-menu > li {
  margin: 0;
  padding: 0;
  display: block;
  position: relative;
}

.horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > ul.sub-menu > li > ul.sub-menu > li > a {
  color: #6b6f80;
  text-align: left;
  text-transform: none;
  letter-spacing: normal;
  background-image: none;
  border-right: 0 solid;
  padding: 8px 12px;
  font-size: 13px;
  line-height: 22px;
  display: block;
}

.horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > ul.sub-menu > li > ul.sub-menu > li > a:hover {
  text-decoration: none;
}

.horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > ul.sub-menu > li > ul.sub-menu > li > a > .fa {
  margin-right: 9px;
}

.horizontalMenu > .horizontalMenu-list > li > a.active, .horizontalMenu > .horizontalMenu-list > li > a:hover {
  color: #fff;
  background: #e3d4ff66;
  text-decoration: none;
}

.horizontalMenu > .horizontalMenu-list > li > ul.sub-menu .sub-menu-sub:after {
  content: "";
  color: #70768e;
  font-family: feather;
  font-size: 13px;
  position: absolute;
  top: 9px;
  right: 14px;
}

.horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > a:hover, .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > ul.sub-menu > li > a:hover, .horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu .link-list li a:hover {
  color: #60529f;
  text-decoration: none;
}

.horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu {
  width: 100%;
  color: #6b6f80;
  z-index: 1000;
  text-align: left;
  margin: 0;
  font-size: 15px;
  position: absolute;
  top: 53px;
  left: 0;
}

.horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu .megamenu-content {
  background-color: #fff;
  border: 1px solid #a7b4c94d;
  padding: 14px 20px;
}

.horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu .title {
  color: #424242;
  text-align: left;
  margin: 0 0 4px;
  padding: 5px 5px 5px 0;
  font-size: 16px;
  font-weight: 600;
}

@media (min-width: 1024px) {
  .horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu .link-list:first-child {
    border: 0;
  }
}

.horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu .wsmwnutxt {
  width: 100%;
  text-align: justify;
  color: #424242;
  margin-top: 10px;
  font-size: 14px;
  line-height: 22px;
}

.horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu .link-list li {
  text-align: center;
  white-space: nowrap;
  text-align: left;
  padding: 5px 10px;
  display: block;
}

.horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu .link-list li a {
  text-align: left;
  color: #646367;
  background-image: none;
  border-right: 0;
  padding: 6px 0;
  font-size: 13px;
  line-height: 18px;
  display: block;
}

.horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu li .fa {
  text-align: center;
  width: 18px;
  margin-right: 5px;
}

.horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu li a:hover {
  background: none;
  text-decoration: none;
}

.horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu .link-list li .fa {
  font-size: 12px;
}

.horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu li .fa {
  text-align: center;
  width: 18px;
  margin-right: 5px;
}

.horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu .mrgtop {
  margin-top: 15px;
}

.horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu .show-grid div {
  color: #6a6a6a;
  background-color: #dbdbdb;
  border: 1px solid #e7e7e7;
  margin: 2px 0;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 13px;
}

.horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu.halfmenu {
  width: 30%;
  left: auto;
  right: auto;
}

.horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu.halfdiv {
  width: 20%;
  left: auto;
  right: auto;
}

.horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu .menu_form {
  width: 100%;
  display: block;
}

.horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu .menu_form input[type="text"] {
  width: 100%;
  color: #6b6f80;
  border: 1px solid #e2e2e2;
  margin-bottom: 8px;
  padding: 8px 5px;
  font-size: 13px;
}

.horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu .menu_form textarea {
  width: 100%;
  color: #6b6f80;
  min-height: 122px;
  border: 1px solid #e2e2e2;
  margin-bottom: 8px;
  padding: 8px 5px;
  font-size: 13px;
}

.horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu .menu_form input[type="submit"] {
  width: 25%;
  height: 32px;
  float: right;
  cursor: pointer;
  background-color: #e1e1e1;
  border: none;
  border-radius: 2px;
  margin-right: 15px;
  display: block;
}

.horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu .menu_form input[type="button"] {
  width: 25%;
  height: 32px;
  float: right;
  cursor: pointer;
  background-color: #e1e1e1;
  border: none;
  border-radius: 2px;
  display: block;
}

.horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu .carousel-inner .item img {
  width: 100%;
}

.horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu .carousel-control-next, .horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu .carousel-control-prev {
  opacity: .8;
}

.horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu .carousel-caption {
  height: 31px;
  width: 100%;
  background-color: #000000b3;
  padding: 7px 0;
  font-size: 13px;
  bottom: 0;
  left: 0;
  right: 0;
}

.horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu .typography-text {
  padding: 0;
  font-size: 14px;
}

.horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu .typography-text ul {
  margin: 0;
  padding: 0;
}

.horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu .typography-text p {
  text-align: justify;
  color: #656565;
  line-height: 24px;
}

.horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu .typography-text ul li {
  padding: 2px 0;
  line-height: 22px;
  display: block;
}

.horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu .typography-text ul li a {
  color: #656565;
}

.horizontal-header {
  display: none;
}

.overlapblackbg {
  opacity: 0;
  visibility: hidden;
}

.horizontalMenu .horizontalMenu-click, .horizontalMenu .horizontalMenu-click02, .hometext {
  display: none;
}

@media only screen and (max-width: 991px) {
  body.active {
    overflow: hidden;
  }

  .desktoplogo {
    display: none;
  }

  .horizontal-main {
    height: 0;
    padding: 0;
  }

  .horizontalMenucontainer {
    background-position: 33% 0;
    background-attachment: local;
  }

  .horizontalMenu {
    width: 100%;
    visibility: hidden;
    background: none;
    margin: 0;
    padding: 0;
    position: fixed;
    top: 0;
    left: 0;
    overflow-y: hidden;
  }

  .horizontalMenu > .horizontalMenu-list {
    height: auto;
    min-height: 100%;
    width: 240px;
    text-align: center;
    background: linear-gradient(#815eae, #4b4992);
    margin-left: -240px;
    padding-bottom: 0;
    display: block;
  }

  .horizontalMenu > .horizontalMenu-list > li {
    width: 100%;
    float: none;
    white-space: inherit;
    clear: right;
    background-color: #0000;
    border-right: none;
    padding: 0;
    display: block;
    position: relative;
  }

  .horizontalMenu > .horizontalMenu-list > li > a {
    text-align: left;
    color: #fff;
    border-bottom: 1px solid #ffffff21;
    border-right: 0 solid;
    padding: 12px 32px 12px 17px;
    font-size: 14px;
    line-height: 25px;
    position: static;
  }

  .horizontalMenu > .horizontalMenu-list > li > a span.fa.fa-caret-down {
    display: none;
  }

  .horizontalMenu > .horizontalMenu-list > li a.menuhomeicon {
    border-top: 1px solid #ffffff21;
    padding-left: 17px;
    padding-right: 17px;
  }

  .horizontalMenu > .horizontalMenu-list > li > a > .fa {
    color: #fff;
    font-size: 16px;
  }

  .horizontalMenu > .horizontalMenu-list > li > a .arrow:after {
    display: none;
  }

  .horizontalMenu > .horizontalMenu-list > li:hover > a {
    background-color: #00000014;
    text-decoration: none;
  }

  .horizontalMenu > .horizontalMenu-list > li > a > .hometext {
    display: inline-block;
  }

  .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu {
    opacity: 1;
    visibility: visible;
    -webkit-transform-style: flat;
    background-color: #ffffff0a;
    border: none;
    padding: 0;
    transition: inherit;
    display: none;
    position: relative;
    top: 0;
    transform: none;
  }

  .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > a {
    color: #cfced0;
    padding: 10px 0 10px 16px;
    font-size: 13px;
    line-height: 20px;
  }

  .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li span + a {
    padding-right: 30px;
  }

  .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > a > .fa {
    display: none;
  }

  .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > a:hover {
    color: #6b6f80;
    background-color: #e7e7e7;
    text-decoration: underline;
  }

  .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu li:hover > a {
    color: #6b6f80;
    background-color: #e7e7e7;
  }

  .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > ul.sub-menu {
    width: 100%;
    opacity: 1;
    visibility: visible;
    -webkit-transform-style: flat;
    border: 0 solid;
    margin: 0;
    padding: 0;
    transition: inherit;
    display: none;
    position: static;
    top: 0;
    left: 100%;
    transform: none;
  }

  .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > ul.sub-menu > li {
    margin: 0;
    padding: 0;
    position: relative;
  }

  .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > ul.sub-menu > li > a {
    padding: 10px 0 10px 26px;
    font-size: 13px;
    line-height: 20px;
    color: #cfced0 !important;
  }

  .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > ul.sub-menu > li span + a {
    padding-right: 30px;
  }

  .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > ul.sub-menu > li > a > .fa {
    display: none;
  }

  .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > ul.sub-menu > li > a:hover {
    color: #6b6f80;
    background-color: #e7e7e7;
    text-decoration: underline;
  }

  .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > ul.sub-menu > li > a.active, .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > ul.sub-menu > li:hover > a {
    color: #6b6f80;
  }

  .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > ul.sub-menu > li > ul.sub-menu {
    width: 100%;
    opacity: 1;
    visibility: visible;
    -webkit-transform-style: flat;
    border: 0 solid;
    margin: 0;
    padding: 0;
    transition: inherit;
    display: none;
    position: static;
    top: 0;
    left: 100%;
    transform: none;
  }

  .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > ul.sub-menu > li > ul.sub-menu > li {
    margin: 0;
  }

  .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > ul.sub-menu > li > ul.sub-menu > li > a {
    color: #6b6f80;
    padding: 10px 0 10px 34px;
    font-size: 13px;
    line-height: 20px;
  }

  .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > ul.sub-menu > li > ul.sub-menu > li span + a {
    padding-right: 30px;
  }

  .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > ul.sub-menu > li > ul.sub-menu > li > a > .fa {
    display: none;
  }

  .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > ul.sub-menu > li > ul.sub-menu > li > a:hover {
    color: #6b6f80;
    background-color: #e7e7e7;
    text-decoration: underline;
  }

  .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > ul.sub-menu > li > ul.sub-menu > li > a.active {
    color: #6b6f80;
  }

  .horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu {
    color: #6b6f80;
    opacity: 1;
    visibility: visible;
    -webkit-transform-style: flat;
    border: 0 solid;
    border-bottom: 1px solid #ffffff21;
    padding: 10px 0;
    transition: inherit;
    display: none;
    position: relative;
    top: 0;
    transform: none;
  }

  .horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu.halfmenu {
    width: 100%;
    margin: 0;
    padding: 5px 0 10px;
  }

  .horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu .title {
    color: #6b6f80;
    padding: 10px 8px 10px 0;
    font-size: 15px;
  }

  .horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu > ul {
    width: 100%;
    margin: 0;
    padding: 0;
    font-size: 13px;
  }

  .horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu > ul > li > a {
    color: #6b6f80;
    background-color: #e7e7e7;
    padding: 9px 14px;
    font-size: 13px;
    line-height: normal;
  }

  .horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu > ul > li > a:hover {
    background-color: #6b6f80000;
  }

  .horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu ul li.title {
    color: #6b6f80;
    background-color: #0000;
    margin: 0;
    padding: 7px 0;
    font-size: 15px;
    line-height: 26px;
  }

  .horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu.halfdiv {
    width: 100%;
  }

  .horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu .menu_form {
    padding: 5px 0 62px;
  }

  .horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu .show-grid div {
    margin: 0;
  }

  .horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu .menu_form input[type="button"], .horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu .menu_form input[type="submit"] {
    width: 46%;
  }

  .horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu .menu_form textarea {
    min-height: 100px;
  }

  .horizontal-header {
    width: 100%;
    z-index: 1;
    height: 54px;
    text-align: center;
    -o-transition: all .25s ease-in-out;
    background-color: #eaecf0;
    transition: all .25s ease-in-out;
    display: block;
    position: relative;
    top: 0;
    left: 0;
    box-shadow: 0 0 1px #0000004d;
  }

  .active .horizontal-header {
    -o-transition: all .25s ease-in-out;
    transition: all .25s ease-in-out;
  }

  .horizontal-header .smllogo {
    margin-top: 15px;
    display: inline-block;
  }

  .horizontalMenu > .horizontalMenu-list > li.rightmenu > .topmenusearch {
    width: 86%;
    margin: 7%;
    padding: 0;
  }

  .horizontalMenu > .horizontalMenu-list > li.rightmenu > .topmenusearch input {
    border-radius: 50px;
  }

  .horizontalMenu > .horizontalMenu-list > li.rightmenu > .topmenusearch input:focus {
    width: 100%;
  }

  .callusbtn {
    color: #6b6f80;
    z-index: 102;
    padding: 11px 14px;
    font-size: 21px;
    transition: all .4s ease-in-out;
    position: absolute;
    top: 0;
    right: 5px;
  }

  .callusbtn .fa {
    vertical-align: top;
    margin-top: 4px;
  }

  .callusbtn:hover .fa {
    color: #6b6f80;
  }

  .animated-arrow {
    z-index: 102;
    -o-transition: all .4s ease-in-out;
    cursor: pointer;
    margin: 15px 0 0 15px;
    padding: 16px 35px 16px 0;
    transition: all .4s ease-in-out;
    position: absolute;
    top: -2px;
  }

  .animated-arrow span, .animated-arrow span:before, .animated-arrow span:after {
    cursor: pointer;
    height: 2px;
    width: 17px;
    content: "";
    background: #646367;
    display: block;
    position: absolute;
  }

  .animated-arrow span:before {
    width: 23px;
    top: -7px;
  }

  .animated-arrow span:after {
    width: 17px;
    bottom: -7px;
  }

  .animated-arrow span, .animated-arrow span:before, .animated-arrow span:after {
    transition: all .5s ease-in-out;
  }

  .active .animated-arrow span:after {
    width: 23px;
    top: -3px;
  }

  .active .animated-arrow span {
    background-color: #0000;
  }

  .active .animated-arrow span:before, .active .animated-arrow.active span:after {
    top: -3px;
  }

  .active .animated-arrow span:before {
    bottom: 0;
    transform: rotate(45deg);
  }

  .active .animated-arrow span:after {
    transform: rotate(-45deg);
  }

  .overlapblackbg {
    width: calc(100% - 240px);
    height: 100vh;
    min-height: 100%;
    opacity: 0;
    visibility: hidden;
    cursor: pointer;
    background-color: #00000073;
    position: fixed;
    top: 0;
    right: 0;
  }

  .active .horizontalMenu .overlapblackbg {
    opacity: 1;
    visibility: visible;
    -o-transition: opacity 1.5s ease-in-out;
    -webkit-transition: opacity 1.5s ease-in-out;
    -moz-transition: opacity 1.5s ease-in-out;
    -ms-transition: opacity 1.5s ease-in-out;
  }

  .horizontalMenucontainer {
    -o-transition: all .25s ease-in-out;
    transition: all .25s ease-in-out;
  }

  .active .horizontalMenucontainer {
    -o-transition: all .25s ease-in-out;
    margin-left: 240px;
    transition: all .25s ease-in-out;
  }

  .active .horizontalMenu {
    -webkit-overflow-scrolling: touch;
    visibility: visible;
    z-index: 1000;
    top: 0;
    overflow-y: scroll;
    height: 100% !important;
  }

  .active .horizontalMenu > .horizontalMenu-list {
    -o-transition: all .25s ease-in-out;
    margin-left: 0;
    transition: all .25s ease-in-out;
  }

  .horizontalMenu > .horizontalMenu-list > li > .horizontalMenu-click {
    height: 49px;
    cursor: pointer;
    width: 100%;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
  }

  .horizontalMenu > .horizontalMenu-list > li .horizontalMenu-click.ws-activearrow > i {
    transform: rotate(180deg);
  }

  .horizontalMenu > .horizontalMenu-list > li > .horizontalMenu-click > i {
    height: 23px;
    width: 25px;
    color: #fff3;
    float: right;
    background-size: 25px;
    margin-top: 14px;
    margin-right: 10px;
    font-size: 21px;
    display: block;
  }

  .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li .horizontalMenu-click02 {
    height: 41px;
    cursor: pointer;
    width: 100%;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
  }

  .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li .horizontalMenu-click02 > i {
    height: 23px;
    width: 25px;
    color: #fff3;
    float: right;
    background-size: 25px;
    margin: 8px 6px;
    font-size: 21px;
    display: block;
  }

  .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li .horizontalMenu-click02 > i.horizontalMenu-rotate {
    transform: rotate(180deg);
  }

  .mega-menubg {
    box-shadow: none !important;
    background: #fff3 !important;
    border: 0 !important;
    padding: 0 !important;
  }

  .horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu .link-list li a {
    color: #cfced0 !important;
  }

  .horizontalMenu > .horizontalMenu-list > li > a.sub-icon:before, .horizontalMenu > .horizontalMenu-list > li > a:hover.sub-icon:before, .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu .sub-menu-sub:after, .horizontalMenu > .horizontalMenu-list > li > a .hor-icon {
    display: none;
  }

  .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > a:hover {
    color: #60529f;
    background: #e3d4ff33;
    text-decoration: none;
  }

  .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu li:hover > a {
    background: #60529f;
    color: #fff !important;
  }

  .horizontalMenu > .horizontalMenu-list > li:hover > a {
    background: #60529f;
    text-decoration: none;
    color: #fff !important;
  }

  .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li:hover > a {
    color: #fff;
    text-decoration: none;
  }

  .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > ul.sub-menu {
    background: #ffffff1a;
    box-shadow: none !important;
    border-radius: 0 !important;
  }

  .mega-menubg:before {
    display: none;
  }

  .mega-menubg ul {
    padding: 0;
  }

  .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu {
    box-shadow: none;
    border-radius: 0 !important;
  }

  .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu:before {
    display: none;
  }

  .horizontalMenu > .horizontalMenu-list > li > a.active > .fa, .horizontalMenu > .horizontalMenu-list > li:hover > a > .fa {
    color: #fff !important;
  }

  .horizontalMenu > .horizontalMenu-list > li > a > .horizontal-icon {
    display: none;
  }
}

@media (min-width: 993px) and (max-width: 1024px) {
  .horizontalMenu > .horizontalMenu-list > li {
    font-size: 10px;
  }

  .horizontalMenu > .horizontalMenu-list > li > a .fa {
    margin-right: 8px;
    font-size: 13px;
  }
}

@media (min-width: 1025px) and (max-width: 1366px) {
  .horizontalMenu {
    width: 100%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1162px) {
  .desktoplogo {
    margin-left: 12px;
  }

  .horizontalMenu > .horizontalMenu-list > li > a > .arrow {
    display: none;
  }

  .horizontalMenu > .horizontalMenu-list > li > a {
    padding-left: 12px;
    padding-right: 12px;
  }

  .horizontalMenu > .horizontalMenu-list > li a.menuhomeicon {
    padding-left: 22px;
    padding-right: 22px;
  }
}

.app-header {
  height: 62px;
  background: #fff;
  padding-top: 0;
  padding-bottom: 0;
}

/*# sourceMappingURL=index.960f0032.css.map */
